
import HeaderNavigation from '@/components/HeaderNavigation'

export default {
  name: 'DefaultLayout',
  components: {
    HeaderNavigation,
    FooterMain: () => import('@/components/FooterMain'),
    FooterBottom: () => import('@/components/FooterBottom'),
    // ScrollProgress: () => import('@/components/widgets/ScrollProgress')
  },
  data () {
    return {
      performanceScore: 'Loading...'
    }
  },
  head () {
    let normalisedPath = this.$route.path
    normalisedPath = normalisedPath.replace(/\/$/, '')
    normalisedPath = (normalisedPath.endsWith('/') ? normalisedPath.slice(0, -1) : normalisedPath)

    normalisedPath = normalisedPath.includes('/tags/') ? normalisedPath.slice(0, normalisedPath.indexOf('/tags/')) : normalisedPath

    return {
      titleTemplate: '%s - AYKO',
      link: [
        {
          rel: 'canonical',
          href: normalisedPath ? (this.$config.NUXT_URL + normalisedPath) : (this.$config.NUXT_URL + '/')
        }
      ]
    }
  },
  mounted () {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        const duration = Math.ceil(entry.loadEventEnd - entry.startTime);
        if (duration > 0) {
          this.performanceScore = `Loaded in ${duration}ms`
        } else {
          this.performanceScore = ``
        }
      });
    });
    observer.observe({ type: 'navigation', buffered: true });
  }
}
